<template>
    <div>
        <CardUsuario class="item"/>
        <FormularioAlterarSenha class="item"/>
    </div>
</template>

<script>
import CardUsuario from "../components/CardUsuario.vue";
import FormularioAlterarSenha from '../components/FormularioAlterarSenha.vue'
    export default {
        components:{
            CardUsuario,
            FormularioAlterarSenha
        }
    }
</script>

<style  scoped>
.item{
  margin: 1.5rem 0;
}
</style>