<template>
  <v-card flat class="formulario-card">
    <div class="title">
      <span> Alterar senha </span>
    </div>
    <v-form class="formulario" v-model="valid" ref="form" lazy-validation>
      <div class="senha-atual">
        <span> Senha Atual</span>

        <v-text-field
          v-model="user.currentPassword"
          placeholder="Digite a senha atual"
          :rules="[(v) => !!v || 'Por favor, insira sua senha atual']"
          color="secondary"
          required
          dense
          outlined
          rounded
          class="input"
        />
      </div>

      <div class="nova-senha">
        <span>Nova Senha</span>
        <v-text-field
          v-model="user.newPassword"
          placeholder="Digite a nova senha"
          :rules="[(v) => !!v || 'Por favor, insira o seu e-mail']"
          color="secondary"
          required
          dense
          outlined
          rounded
          class="input"
        />
      </div>
      <div class="nova-senha-confirmacao">
        <span>Confirmar Nova Senha</span>
        <v-text-field
          v-model="confirmPassword"
          placeholder="Confirmar a nova senha"
          :rules="[(v) => !!v || 'Por favor, insira sua senha novamente']"
          color="secondary"
          required
          dense
          outlined
          rounded
          class="input"
        />
      </div>
    </v-form>

    <div class="botoes">
      <div class="cancelar" @click="goBack">
        <span>Cancelar</span>
      </div>
      <v-btn :loading="loading"   color="primary" class="alterar" @click="atualizarSenha">
        <span>Salvar</span>
      </v-btn>
    </div>
  </v-card>
</template>

<script>
import ApiService from "../../../../services/ApiService";
export default {
  data() {
    return {
      items: ["CPF", "CNPJ"],
      valid: true,
      loading: false,
      user: {
        currentPassword: "",
        newPassword: "",
      },
      userLogged: undefined,
      confirmPassword: "",
      apiService: new ApiService(),
    };
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    validate(){
        return this.$refs.form.validate()
    },
    senhasIguais() {
      if(this.user.newPassword == this.confirmPassword)
      {
        return true
      }
      this.$toast.error('A nova senha e confirmação de senha estão diferentes')
    },
    zerarCampos(){
        this.user.currentPassword = ''
        this.user.newPassword = ''
        this.confirmPassword = ''
    },
    async atualizarSenha() {
        
      if (this.senhasIguais() && this.validate()) {
       this.user.id = this.userLogged.id;
         
        try {
            
          this.loading = true
          const req = await this.apiService.post("user/password/update",this.user );
          this.loading = false

          if(req.statusCode){
              this.$toast.success(req.message)

              setTimeout(() =>{
                  this.$router.push({name: 'cadastro'})
              }, 2500)
          }
          
        } catch (error) {
          this.loading = false
          this.$toast.error('Erro ao tentar atualizar senha')
        }
      }

    },
  },
  created() {
    this.userLogged = this.$store.getters.$userLogged;
  },
};
</script>

<style lang="scss" scoped>
.formulario-card {
  border: 1px solid #dadada;
  border-radius: 7px;
  padding: 2rem;
  display: flex;
  flex-wrap: wrap-reverse;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  align-items: flex-end;
}
.title span {
  font-weight: 800;
  font-size: 1.2em;
}
.formulario-card .formulario,
.img {
  margin: 0.5rem;
}
.formulario {
  width: 80%;
  transition: 0.2s ease-in-out;
}
.formulario > div {
  width: 100%;
}
.botoes {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  justify-content: flex-end;
}
.botoes > div, .alterar {
  margin: 0.3rem;
  padding: 0.3rem 1rem;
  border-radius: 20px;
  cursor: pointer;
}
.cancelar {
  border: 1px solid var(--principal-azul);
  color: var(--principal-azul);
}
.alterar {
  background: var(--principal-azul);
  color: white;
}
.compra {
  position: absolute;
  top: 15px;
  right: 20px;
}
.compra span {
  font-weight: 300;
  font-size: 0.8em;
}
@media screen and (max-width: 750px) {
  .formulario {
    width: 100%;
  }
  .botoes {
    width: 100%;
    justify-content: center;
    margin: 0 auto;
  }
}
</style>