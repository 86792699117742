var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { staticClass: "formulario-card", attrs: { flat: "" } },
    [
      _c("div", { staticClass: "title" }, [
        _c("span", [_vm._v(" Alterar senha ")]),
      ]),
      _c(
        "v-form",
        {
          ref: "form",
          staticClass: "formulario",
          attrs: { "lazy-validation": "" },
          model: {
            value: _vm.valid,
            callback: function ($$v) {
              _vm.valid = $$v
            },
            expression: "valid",
          },
        },
        [
          _c(
            "div",
            { staticClass: "senha-atual" },
            [
              _c("span", [_vm._v(" Senha Atual")]),
              _c("v-text-field", {
                staticClass: "input",
                attrs: {
                  placeholder: "Digite a senha atual",
                  rules: [(v) => !!v || "Por favor, insira sua senha atual"],
                  color: "secondary",
                  required: "",
                  dense: "",
                  outlined: "",
                  rounded: "",
                },
                model: {
                  value: _vm.user.currentPassword,
                  callback: function ($$v) {
                    _vm.$set(_vm.user, "currentPassword", $$v)
                  },
                  expression: "user.currentPassword",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "nova-senha" },
            [
              _c("span", [_vm._v("Nova Senha")]),
              _c("v-text-field", {
                staticClass: "input",
                attrs: {
                  placeholder: "Digite a nova senha",
                  rules: [(v) => !!v || "Por favor, insira o seu e-mail"],
                  color: "secondary",
                  required: "",
                  dense: "",
                  outlined: "",
                  rounded: "",
                },
                model: {
                  value: _vm.user.newPassword,
                  callback: function ($$v) {
                    _vm.$set(_vm.user, "newPassword", $$v)
                  },
                  expression: "user.newPassword",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "nova-senha-confirmacao" },
            [
              _c("span", [_vm._v("Confirmar Nova Senha")]),
              _c("v-text-field", {
                staticClass: "input",
                attrs: {
                  placeholder: "Confirmar a nova senha",
                  rules: [
                    (v) => !!v || "Por favor, insira sua senha novamente",
                  ],
                  color: "secondary",
                  required: "",
                  dense: "",
                  outlined: "",
                  rounded: "",
                },
                model: {
                  value: _vm.confirmPassword,
                  callback: function ($$v) {
                    _vm.confirmPassword = $$v
                  },
                  expression: "confirmPassword",
                },
              }),
            ],
            1
          ),
        ]
      ),
      _c(
        "div",
        { staticClass: "botoes" },
        [
          _c("div", { staticClass: "cancelar", on: { click: _vm.goBack } }, [
            _c("span", [_vm._v("Cancelar")]),
          ]),
          _c(
            "v-btn",
            {
              staticClass: "alterar",
              attrs: { loading: _vm.loading, color: "primary" },
              on: { click: _vm.atualizarSenha },
            },
            [_c("span", [_vm._v("Salvar")])]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }